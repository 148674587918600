.custom-page {
  font-family: sans-serif;
  background-color: #ffffff;
}

.image-container {
  border-radius: 8px;
  padding: 1rem;
  text-align: center;
}

.options label {
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.ring-select,
select {
  display: block;
  width: 100%;
  padding: 0.75rem;
  font-size: 0.875rem;
  border: 1px solid #d1d5db;
  border-radius: 0.375rem;
}

.view-button {
  font-weight: 600;
  width: 100%;
  max-width: 200px;
  margin-top: 1rem;
}
