@tailwind base;
@tailwind components;
@tailwind utilities;


.btn-primary {
  @apply bg-indigo-600 text-white font-semibold py-2 px-4 rounded;
}

.btn-secondary {
  @apply bg-gray-200 text-gray-700 font-semibold py-2 px-4 rounded;
}

.btn-small {
  @apply text-sm;
}

body {
  margin: 0;
  font-family: -apple-system, Oswald-SemiBold, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face{
  font-family: "Oswald-SemiBold";
  src: url("components/fonts/Oswald-SemiBold.ttf") format("truetype");
}
